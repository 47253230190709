<template>
  <div class="admin-list">
    <PageTitle :title="$t('page.branchSetting.title')" :btn="$t('common.button.create.text')" @btnClick="openDialog('create')" />
    <FiltersContainer>
      <BaseElInput v-model="nameSearch" clearable :placeholder="$t('branchSetting.searchName.placeholder')" @keypress.enter.native="refresh" @clear="refresh">
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh" />
      </BaseElInput>
    </FiltersContainer>

    <section>
      <BaseTable v-loading="loading" :data="shopList" :empty-text="$t('common.table.empty.text')">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn :label="$t('branchSetting.table.name.title')" prop="name" align="center" />
        <BaseElTableColumn :label="$t('branchSetting.table.phone.title')" prop="phone" align="center" />
        <BaseElTableColumn v-if="isOverseasShop" :label="$t('branchSetting.table.currency.title')" prop="currency" align="center" />
        <BaseElTableColumn v-if="isOverseasShop" :label="$t('branchSetting.table.timezone.title')" prop="timezone" align="center">
          <template slot-scope="scope">
            {{ shopTimezone(scope.row) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn :label="$t('branchSetting.table.address.title')" prop="address" align="center" />
        <BaseElTableColumn :label="$t('branchSetting.table.email.title')" prop="email" align="center">
          <template slot-scope="scope">
            {{ scope.row.email || '-' }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn :label="$t('branchSetting.table.contract.title')" align="center">
          <template slot-scope="scope">
            {{ shopContractName(scope.row) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn fixed="right" :label="$t('common.table.action.text')" width="100" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              hideDelete
              @edit="openDialog('update'), (selectRow = scope.row), syncFormData()"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="shopsCount"
        @pageChange="changePage"
      />
    </section>

    <!-- Dialog -->
    <el-dialog
      v-if="showDialog"
      :visible="showDialog"
      :close-on-click-modal="false"
      :title="dialogType === 'create' ? $t('branchSetting.dialog.create.title') : $t('branchSetting.dialog.edit.title')"
      @close="resetForm(), showDialog = false"
    >
      <BaseElForm
        ref="form"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <BaseElFormItem>
          <div>
            <p>{{ $t('branchSetting.dialog.imageSetting.title') }}</p>
            <p>{{ $t('branchSetting.dialog.imageSetting.subTitle') }}</p>
          </div>
          <UploadButton
            :img="formData.img"
            :isAvatar="true"
            @change="loadImg"
          />
        </BaseElFormItem>
        <BaseElFormItem :label="$t('branchSetting.dialog.branchName.title')" prop="name">
          <BaseElInput v-model="formData.name" :placeholder="$t('branchSetting.dialog.branchName.placeholder')" />
        </BaseElFormItem>

        <BaseElFormItem :label="$t('branchSetting.dialog.phone.title')" prop="phone">
          <BaseElInput v-model="formData.phone" :placeholder="$t('branchSetting.dialog.phone.placeholder')" />
        </BaseElFormItem>

        <!-- <BaseElFormItem v-if="isOverseasShop" :label="$t('branchSetting.dialog.address.title')">
          <BaseElFormItem label="都道府県" prop="prefecture">
            <BaseElSelect v-model="formData.prefecture"  @change="onPrefectureChange" placeholder="都道府県を選択する">
              <BaseElSelectOption v-for="(cities, prefecture) in japanCities" :key="prefecture" :label="prefecture" :value="prefecture" />
            </BaseElSelect>
          </BaseElFormItem>
          <BaseElFormItem label="市区町村" prop="city">
            <BaseElSelect v-model="formData.city" placeholder="請選擇市区町村">
              <BaseElSelectOption v-for="city in cities" :key="city" :label="city" :value="city" />
            </BaseElSelect>
          </BaseElFormItem>
          <BaseElFormItem label="詳細地址" prop="detailAddress">
            <BaseElInput v-model="formData.detailAddress" placeholder="請輸入詳細地址" />
          </BaseElFormItem>
        </BaseElFormItem> -->
        <BaseElFormItem :label="$t('branchSetting.dialog.address.title')" prop="address">
          <BaseElInput v-model="formData.address" :placeholder="$t('branchSetting.dialog.address.placeholder')" />
        </BaseElFormItem>

        <BaseElFormItem :label="$t('branchSetting.dialog.googleMapLink.title')" prop="googleMapLink">
          <BaseElInput v-model="formData.googleMapLink" :placeholder="$t('branchSetting.dialog.googleMapLink.placeholder')" />
        </BaseElFormItem>

        <BaseElFormItem :label="$t('branchSetting.dialog.email.title')" prop="email">
          <BaseElInput v-model="formData.email" :placeholder="$t('branchSetting.dialog.email.placeholder')" />
        </BaseElFormItem>
        <BaseElFormItem v-if="isOverseasShop" :label="$t('branchSetting.dialog.currency.title')" prop="currency">
          <BaseElSelect v-model="formData.currency" :placeholder="$t('branchSetting.dialog.currency.placeholder')" :disabled="dialogType !== 'create'">
            <BaseElSelectOption
              v-for="option in currencyOptions"
              :key="option.value"
              :value="option.value"
              :label="option.label"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem v-if="isOverseasShop" :label="$t('branchSetting.dialog.timezone.title')" prop="timezone">
          <BaseElSelect v-model="formData.timezone" :placeholder="$t('branchSetting.dialog.timezone.placeholder')" :disabled="dialogType !== 'create'">
            <BaseElSelectOption
              v-for="option in timezoneOptions"
              :key="option.value"
              :value="option.value"
              :label="option.label"
            />
          </BaseElSelect>
        </BaseElFormItem>

        <BaseElFormItem :label="$t('branchSetting.dialog.industryCategory.title')" prop="industry">
          <div class="grid gap-[8px] grid-flow-col">
            <BaseElSelect v-model="formData.industryCategory" :placeholder="$t('branchSetting.dialog.industryCategory.placeholder')" @change="updateIndustry">
              <BaseElSelectOption
                v-for="option in industryCategories"
                :key="option.key"
                :value="option.key"
                :label="option.name"
              />
            </BaseElSelect>
            <BaseElSelect v-model="formData.industry" :disabled="!formData.industryCategory" :placeholder="$t('branchSetting.dialog.subIndustryCategory.placeholder')">
              <BaseElSelectOption
                v-for="option in subIndustryCategories"
                :key="option.key"
                :value="option.key"
                :label="option.name"
              />
            </BaseElSelect>
          </div>
        </BaseElFormItem>
        <BaseElFormItem :label="$t('branchSetting.dialog.contract.title')" prop="ContractPlanId">
          <ContractPlanSelect
            :mode="dialogType"
            :shopPlan="formData.ContractPlan"
            :model.sync="formData.ContractPlanId"
          />
        </BaseElFormItem>
      </BaseElForm>

      <div slot="footer">
        <BaseElButton
          plain
          @click=";(showDialog = false), resetForm()"
        >
          {{ $t('common.button.cancel.text')}}
        </BaseElButton>
        <BaseElButton v-loading="btnLoading" type="primary" @click="handleDialogConfirm">
          {{
            dialogType === "create" ? $t('common.button.create.text') : $t('common.button.save.text')
          }}
        </BaseElButton>
      </div>
    </el-dialog>
    <ImageCropper
      v-if="uploadDialog"
      :image="uploadImg"
      @close="uploadDialog = false"
      @uploaded="getImage"
    />
  </div>
</template>

<script>
// import SearchInput from "@/components/SearchInput"
import ContractPlanSelect from '@/components/Select/ContractPlanSelect.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import imageMixin from '@/mixin/image'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { GetShops, CreateShop, UpdateShop, GetShopsCount, GetShopById, GetIndustryCategories } from '@/api/shop'
import { noEmptyRules, phoneRules, emailRules, emailRulesNoRequired } from '@/validation'

// Utils
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import jpCities from '@/assets/data/japanCities'

import { currencyOptions, timezoneOptions } from '@/config/branchSetting'
import { get } from 'lodash'
import store from '@/store'
import { computed, ref, reactive } from 'vue'

export default {
  name: 'BranchShopList',
  components: { ContractPlanSelect, EmptyBlock, ImageCropper, UploadButton },
  mixins: [imageMixin],
  setup() {
    const japanCities = ref(jpCities)
    const cities = ref([])
    const selectRow = ref(null)
    const isOverseasShop = computed(() => get(store.state, 'org.isOverseasShop'))
    const formData = reactive({
      img: null,
      name: '',
      phone: '',
      address: '',
      email: '',
      industryCategory: '',
      industry: '',
      ContractPlanId: '',
      googleMapLink: '',
      currency: 'TWD',
      timezone: 'Asia/Taipei',
      // prefecture: '',
      // city: '',
      // detailAddress: ''
    })
    const industryCategories = ref([])
    const subIndustryCategories = ref([])

    // const onPrefectureChange = (prefecture) => {
    //   cities.value = japanCities.value[prefecture] || []
    //   formData.city = ''
    // }

    const syncFormData = async () => {
      await findShop()
      const config = JSON.parse(JSON.stringify(selectRow.value))
      Object.assign(formData, config)
      formData.img = config.Image || undefined
      if (config.industry) {
        const industryCategory = industryCategories.value.find(category =>
          category.industries.some(industry => industry.key === config.industry),
        )
        formData.industryCategory = industryCategory.key
        subIndustryCategories.value = industryCategory.industries
      }
      // if (isOverseasShop.value && config.address) {
      //   const address = config.address.split(',')
      //   formData.prefecture = address[0]
      //   formData.city = address[1]
      //   formData.detailAddress = address[2]
      // }
    }
    const findShop = async () => {
      const [res, err] = await GetShopById(selectRow.value.id)
      if (err) return window.$message.error(err || err.msg)
      selectRow.value = res
    }
    const getIndustryCategories = async () => {
      const [res, err] = await GetIndustryCategories()
      if (err) {
        window.$message.error(err)
        return
      }
      industryCategories.value = res.industryCategories
    }
    const shopTimezone = (row) => {
      return get(timezoneOptions[row.timezone], 'label', '-')
    }
    return {
      isOverseasShop,
      japanCities,
      cities,
      // onPrefectureChange,
      formData,
      syncFormData,
      industryCategories,
      subIndustryCategories,
      getIndustryCategories,
      selectRow,
      currencyOptions,
      timezoneOptions,
      shopTimezone,
    }
  },
  data: () => ({
    loading: false,
    btnLoading: false,
    userSearch: '',
    // selectRow: null,
    nameSearch: '',
    // industryCategories: [],
    // subIndustryCategories: [],
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    roleList: ['orgAdmin'],

    shopList: [],
    shopsCount: 0,

    // Dialog
    showDialog: false,
    dialogType: 'create',

    // create user from
    // formData: {
    //   img: null,
    //   name: '',
    //   phone: '',
    //   address: '',
    //   email: '',
    //   industryCategory: '',
    //   industry: '',
    //   ContractPlanId: '',
    //   googleMapLink: '',
    //   currency: 'TWD',
    //   timezone: 'Asia/Taipei',
    //   prefecture: '',
    //   city: '',
    //   detailAddress: ''
    // },

    // from rules
    formRules: {
      name: [noEmptyRules()],
      phone: [noEmptyRules(), phoneRules(true, true, true)],
      address: [noEmptyRules()],
      // prefecture: [noEmptyRules()],
      // city: [noEmptyRules()],
      // detailAddress: [noEmptyRules()],
      email: [emailRulesNoRequired()],
      currency: [noEmptyRules()],
      timezone: [noEmptyRules()],
      ContractPlanId: [noEmptyRules()],
    },
  }),

  computed: {
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
  },

  async mounted () {
    await this.refresh()
  },

  methods: {
    async refresh () {
      await Promise.allSettled([
        this.getShops(),
        this.getShopsCount(),
        this.getIndustryCategories(),
      ])
    },
    showInput (typeList) {
      return !!typeList.includes(this.dialogType)
    },

    async changePage () {
      await this.getShops()
    },
    shopContractName (row) {
      return get(row, 'ContractPlan.name', '-')
    },
    clearFormData () {
      this.formData = {
        img: null,
        name: '',
        phone: '',
        address: '',
        email: '',
        industryCategory: '',
        industry: '',
        ContractPlanId: '',
        googleMapLink: '',
        currency: 'TWD',
        timezone: 'Asia/Taipei',
        // prefecture: '',
        // city: '',
        // detailAddress: ''
      }
    },

    // async syncFormData () {
    //   await this.findShop()
    //   const config = JSON.parse(JSON.stringify(this.selectRow))
    //   console.log(config)
    //   this.formData = config
    //   this.formData.img = config.Image || undefined
    //   if (config.industry) {
    //     const industryCategory = this.industryCategories.find(category =>
    //       category.industries.some(industry => industry.key === config.industry),
    //     )
    //     this.formData.industryCategory = industryCategory.key
    //     this.subIndustryCategories = industryCategory.industries
    //   }
    //   if (config.address) {
    //     const address = config.address.split(',')
    //     this.formData.prefecture = address[0]
    //     this.formData.city = address[1]
    //     this.formData.detailAddress = address[2]
    //   }
    // },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.clearFormData()
      this.selectRow = null
    },

    openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },

    async handleDialogConfirm () {
      if (!(await this.checkForm())) return
      const type = this.dialogType
      try {
        if (type === 'create') await this.createShop()
        if (type === 'update') await this.updateShop()
        this.showDialog = false
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    async findShop () {
      const [res, err] = await GetShopById(this.selectRow.id)
      if (err) return this.$message.error(err || err.msg)
      this.selectRow = res
    },

    //= > 取得店家
    async getShops () {
      this.loading = true
      try {
        this.shopList = await GetShops({
          start: this.pageStartIndex,
          limit: this.tableOptions.pageLimit,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.shopList = this.shopList.map(shop => {
        if (shop.address) {
          shop.address = shop.address.replace(/,/g, '');
        }
      return shop;
    });
        this.loading = false
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取得總店家數量
    async getShopsCount () {
      try {
        this.shopsCount = await GetShopsCount({
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    //= > 取得產業分類
    // async getIndustryCategories () {
    //   const [res, err] = await GetIndustryCategories()
    //   if (err) {
    //     this.$message.error(err)
    //     return
    //   }
    //   this.industryCategories = res.industryCategories
    // },
    updateIndustry () {
      this.formData.industry = ''
      const sub = this.industryCategories.find(
        (category) => category.key === this.formData.industryCategory,
      )
      this.subIndustryCategories = sub.industries
    },
    //= > 創建店家
    async createShop () {
      this.btnLoading = true
      const form = this.formData
      // const address = this.isOverseasShop
      //   ? `${form.prefecture},${form.city},${form.detailAddress}`
      //   : form.address || undefined;
      try {
        const shop = await CreateShop({
          imageId: form.img === null ? null : form.img.id,
          name: form.name,
          phone: form.phone === '' ? undefined : form.phone,
          address: form.address === '' ? undefined : form.address,
          email: form.email === '' ? undefined : form.email,
          industry: form.industry === '' ? undefined : form.industry,
          contractPlanId:
          form.ContractPlanId === 'none' ? undefined : form.ContractPlanId,
          googleMapLink: form.googleMapLink === '' ? undefined : form.googleMapLink,
          currency: this.isOverseasShop ? form.currency : null,
          timezone: this.isOverseasShop ? form.timezone : null,
        })
        await this.getShops()
        await this.$store.dispatch('user/addAllowShop', shop)
        this.$message.success('新增成功!')
        this.btnLoading = false
      } catch (error) {
        this.btnLoading = false
        return this.$message.error(error)
      }
    },

    //= > 更新店家
    async updateShop () {
      this.btnLoading = true
      // const address = this.isOverseasShop
      //   ? `${this.formData.prefecture},${this.formData.city},${this.formData.detailAddress}`
      //   : this.formData.address || undefined;
      try {
        await UpdateShop({
          shopId: this.selectRow.id,
          imageId: this.formData.img?.id || null,
          name: this.formData.name,
          phone: this.formData.phone,
          address: this.formData.address,
          email: this.formData.email,
          industry: this.formData.industry,
          contractPlanId:
          this.formData.ContractPlanId === 'none'
            ? null
            : this.formData.ContractPlanId,
          googleMapLink: this.formData.googleMapLink === '' ? undefined : this.formData.googleMapLink,
        })
        this.selectRow = null
        await this.getShops()
        this.$message.success('更新成功!')
        this.btnLoading = false
      } catch (error) {
        this.btnLoading = false
        return this.$message.error(error)
      }
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .grid .el-input {
  @apply w-auto;
}
</style>
